<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        v-if="canSubmit"
        slot="right"
        icon
        @click.stop="clickSubmit"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <template
        v-for="(valorValidacion, keyValidacion) in validaciones"
      >
        <v-alert
          :key="keyValidacion"
          :value="true"
          :type="valorValidacion.superada ? 'success' : 'error'"
        >
          <v-row
            align="center"
          >
            <v-col
              class="grow"
            >
              {{ valorValidacion.mensaje }}
            </v-col>
            <v-col
              v-if="!valorValidacion.superada"
              class="shrink"
              @click="clickResolver(keyValidacion)"
            >
              <v-btn
                dark
                text
              >
                Resolver
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </template>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { basePageMixin } from '@/mixins/basePageMixin'
import Data from './PresupuestocliAceptarData'
import { PRESUPUESTOCLI, TABLA } from '@/utils/consts'

export default {
  mixins: [basePageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      validaciones: {},
      presupuestocli: {},
    }
  },
  computed: {
    presupuestoPendiente () {
      return (
        this.presupuestocli &&
        PRESUPUESTOCLI.estadosPendientes.includes(this.presupuestocli.idest_presupuestocli)
      )
    },
    canSubmit () {
      const cantValidaciones = Object.keys(this.validaciones).length
      let validacioneSuperadas = 0
      for (const validacion in this.validaciones) {

        if (this.validaciones[validacion].superada) {
          validacioneSuperadas += 1
        }
      }
      return cantValidaciones === validacioneSuperadas
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Aceptar presupuesto'
      const resp = await Data.selectLoadPage(this, this.routeParams.idpresupuestocli)
      this.presupuestocli = resp.data.selectPresupuestocli.result.dataset[0]
      this.validaciones = await this.$online.presupuestocli.validacionesAceptar(this.routeParams.idpresupuestocli)
    },
    clickResolver (validacion) {
      if (validacion === 'direccion' || validacion === 'formaPago' || validacion === 'formaPagoCuotas') {
        this.$appRouter.push({
          name: 'presupuestos__presupuestocli-edit',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else if (validacion === 'cuentaBancaria') {
        if (this.presupuestocli.idcliente_potencial) {
          this.$appRouter.push({
            name: 'cuentasbancarias__clientes-potenciales-cliente-cuenta-add',
            params: {
              idcliente_potencial: this.presupuestocli.idcliente_potencial,
            },
          })
        } else {
          this.$appRouter.push({
            name: 'cuentasbancarias__clientes-cliente-cuenta-add',
            params: {
              idcliente: this.presupuestocli.idcliente,
            },
          })
        }
      } else if (validacion === 'ubicaciones') {
        this.$appRouter.push({
          name: 'presupuestos__ubicaciones__ubi-lpresupuestocli-edit',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else if (validacion === 'datosRequeridos') {
        this.$appRouter.push({
          name: 'datos__presupuestos-dato-edit',
          params: {
            idtabla: TABLA.presupuestocli.idtabla,
            id: this.routeParams.idpresupuestocli,
          },
          query: {
            'validar_requeridos': 1,
          },
        })
      }
    },
    async clickSubmit () {
      await Data.aceptar(this, this.routeParams.idpresupuestocli)
      const resp = await this.$alert.showConfirm(
        `¿Deseas crear un contrato para el presupuesto?`
      )
      if (resp) {
        this.$appRouter.replace({
          name: 'presupuestos__presupuestocli-contrato-add',
          params: {
            idpresupuestocli: this.routeParams.idpresupuestocli,
          },
        })
      } else {
        this.$appRouter.go(-1)
      }
    },
  }
}
</script>
