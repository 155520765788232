import { APIFilter } from '@/utils/api'

export default {
  async selectLoadPage (Vue, idpresupuestocli) {
    const apiCalls = []
    const apiFilterPresupuesto = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
    apiCalls.push({ name: 'selectPresupuestocli', method: 'presupuestocli.select', params: { filter: apiFilterPresupuesto } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async aceptar (Vue, idpresupuestocli) {
    await Vue.$api.call('presupuestocli.aceptar', { idpresupuestocli })
  },
}
